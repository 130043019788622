import styled from '@emotion/styled'
import { ExpandLess, ExpandMore, KeyboardArrowDown } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel as MuiFormControlLabel,
  Select,
  SwipeableDrawer,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  css,
  debounce,
  useScrollTrigger,
} from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalActions, useGlobalStore } from '../../../global-store'
import { getEventSearch, getEvents } from '../../api/events'
import { getVenues } from '../../api/venues'
import { EventObjectTypes } from '../../common-types'
import useEffectExceptOnMount from '../../hooks/useEffectAfterInit'
import { theme } from '../../styles/theme-override'
import { colors, constants, screenSize } from '../../tokens'
import Container from '../container'
import MobileOnly from '../mobile-only'
import Section from '../section'
import dayjs from 'dayjs'

const darkTheme = createTheme({
  palette: { mode: 'dark' },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#90caf9 !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: colors.globalDarkBlueTransparent,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#90caf9',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          paddingTop: '4px',
          fontSize: '13px',
        },
      },
    },
  },
})

const defaultOptionsState = { options: [''], isLoading: false }

interface GlobalEventDataParams {
  eventName?: string
  venueName?: string
  eventStatus?: string
  date?: string | null
  reset?: boolean
}

export default function EventFilters({ style, title }: { style?: any; title?: string }) {
  const { eventFilters } = useGlobalStore()
  const { setCurrentEvents, setEventFilters } = useGlobalActions()
  const [isOpen, setIsOpen] = useState(false)

  const [venuesList, setVenuesList] = useState(defaultOptionsState)

  const trigger = useScrollTrigger({
    target: window ? window : undefined,
    // disableHysteresis: true,
  })

  const setGlobalData = ({ eventName, eventStatus, venueName, date }: GlobalEventDataParams) => {
    setEventFilters({ data: { eventName, venueName, date } })
    setCurrentEvents({ isLoading: true })
    getEvents({
      eventName: eventName || '',
      venueName: venueName || '',
      date: date || '',
      eventStatus,
    })
      .then((results) => {
        if (results?.EventsByRequest) {
          const currentTimestamp = dayjs().valueOf()
          results.EventsByRequest = results?.EventsByRequest.filter((event: any) => {
            const eventTimestamp = dayjs(event.EventDateTime).valueOf() // Convert event time to timestamp
            return eventTimestamp >= currentTimestamp
          })
        }
        setCurrentEvents({
          data: results?.EventsByRequest,
          isLoading: false,
          totalCount: results?.TotalCount,
        })
      })
      .catch(() => setCurrentEvents({ isLoading: false }))
  }

  const onClickMobileButton = () => {
    setIsOpen((oldVal) => !oldVal)
  }

  useEffect(() => {
    if (venuesList.options.length < 2) {
      setVenuesList((prevState) => ({ ...prevState, isLoading: true }))
      getVenues()
        .then((data: any) => {
          if (data && data.length) {
            setVenuesList({ options: ['', ...data], isLoading: false })
          }
        })
        .catch(() => setVenuesList((prevState) => ({ ...prevState, isLoading: false })))
    }
  }, [])

  useEffect(() => {
    if (trigger && isOpen) {
      setIsOpen(false)
    }
  }, [trigger, isOpen])

  return (
    <div style={style}>
      <DesktopContainer style={{ margin: 0 }}>
        <Collapse in={!trigger}>
          <Container>
            <FilterActions
              viewSize="desktop"
              venuesList={venuesList}
              setGlobalData={setGlobalData}
              setIsOpen={setIsOpen}
              title={title}
            />
          </Container>
        </Collapse>
      </DesktopContainer>
      <MobileContainer>
        <Collapse in={!trigger}>
          <div style={{ width: '100%' }}>
            <MobileButtonContainer>
              <Button onClick={onClickMobileButton} sx={{ padding: '6px 12px', minWidth: 'auto' }}>
                {/* <FilterList sx={{ color: colors.globalLightBlue, fontSize: '28px' }} /> */}
                <Typography
                  color={colors.globalLightBlue}
                  variant="body2"
                  style={{
                    paddingLeft: '4px',
                    fontWeight: '500',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>FILTERS</span> {isOpen ? <ExpandLess /> : <ExpandMore />}
                  {/* eventFilters.data?.count > 0 ? `(${eventFilters.data.count})` : '(0)' */}
                </Typography>{' '}
              </Button>
            </MobileButtonContainer>
            <SwipeableDrawer
              anchor="top"
              onOpen={() => setIsOpen(true)}
              open={!trigger && isOpen}
              onClose={() => setIsOpen(false)}
              sx={{
                '& .MuiDrawer-paperAnchorTop': { top: 100 },
                '& .MuiPaper-root': {
                  //  position: 'absolute',
                  backgroundColor: 'transparent',
                },
              }}
            >
              <div style={{ background: colors.globalDarkestBlue, padding: '12px', flex: 1 }}>
                <FilterActions
                  venuesList={venuesList}
                  setGlobalData={setGlobalData}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              </div>
            </SwipeableDrawer>
            {/* <MobileDirectionButton variant="contained" onClick={() => setIsOpen((oldVal) => !oldVal)}>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </MobileDirectionButton> */}
          </div>
        </Collapse>
      </MobileContainer>
    </div>
  )
}

interface FilterActionsType {
  viewSize?: 'desktop' | 'mobile'
  venuesList: {
    options: string[]
    isLoading: boolean
  }
  title?: string
  setGlobalData: Function
  setIsOpen?: any
  isOpen?: boolean
}

/* this is extra clunky but will work for now as we have two different types of components for mobile vs desktop */
function FilterActions({
  viewSize = 'mobile',
  venuesList,
  setGlobalData,
  title,
  setIsOpen,
  isOpen,
}: FilterActionsType) {
  const router = useRouter()
  const [isVenueSelectOpen, setIsVenueSelectOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState('')
  const [selectedVenue, setSelectedVenue] = useState('')
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  // const [filterCount, setFilterCount] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [eventSearchList, setEventSearchList] = useState(defaultOptionsState)
  const [justAdded, setJustAdded] = useState<string>('')

  const onSelectEventOption = (_: any, newOption: string, reason?: string) => {
    setSelectedEvent(newOption)
    if (reason === 'clear') {
      setSearchInput('')
    }
  }

  const onSelectVenueOption = (event: EventObjectTypes) => {
    setSelectedVenue(event?.target?.value)
  }

  const onSelectDateOption = (newVal: string | null) => {
    setSelectedDate(newVal)
  }

  const onClickJustAdded = () => {
    setJustAdded((oldState: string) => {
      return oldState === 'JustAdded' ? '' : 'JustAdded'
    })
  }

  const onInputChange = (_: any, newVal: string) => {
    setSearchInput(newVal)
  }

  const debouncedInputHandler = useMemo(() => debounce(onInputChange, 300), [])

  const onResetFilters = (scroll?: boolean) => {
    setSearchInput('')
    setSelectedEvent('')
    setSelectedVenue('')
    setSelectedDate(null)
    setJustAdded('')
    setIsOpen(false)
    if (scroll) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (searchInput) {
      setEventSearchList((prevState) => ({ ...prevState, isLoading: true }))
      getEventSearch({ searchInput, yearFilter: true })
        .then((data) => setEventSearchList({ options: data || [''], isLoading: false }))
        .catch(() => setEventSearchList((prevState) => ({ ...prevState, isLoading: false })))
    }
  }, [searchInput])

  useEffectExceptOnMount(() => {
    setGlobalData({
      venueName: selectedVenue,
      eventName: selectedEvent || searchInput,
      eventStatus: justAdded,
      date: selectedDate,
    })
  }, [selectedVenue, selectedEvent, justAdded, selectedDate])

  useEffect(() => {
    setGlobalData({})
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <FilterActionsContainer spacing={1}>
        {!!title && (
          <Typography
            component="h1"
            variant="h5"
            color="white"
            sx={{
              paddingRight: constants.globalBufferXL,
              fontSize: '20px',
              fontWeight: 900,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'baseline',
            }}
          >
            <HeaderLink href="/" aria-describedby="Requestix Home">
              {title}
            </HeaderLink>
          </Typography>
        )}
        <Autocomplete
          options={eventSearchList.options}
          filterOptions={(x) => x}
          autoComplete
          freeSolo
          value={selectedEvent}
          // getOptionLabel={getEventOptionLabel}
          isOptionEqualToValue={(opt, val) => opt === val || val === ''}
          blurOnSelect={true}
          onInputChange={debouncedInputHandler}
          onChange={onSelectEventOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by event name"
              fullWidth
              InputProps={{
                ...params.InputProps,
                ...(eventSearchList.isLoading
                  ? { endAdornment: <CircularProgress color="inherit" size={20} /> }
                  : {}),
              }}
            />
          )}
        />
        <FormControl>
          <InputLabel id="venueFilter">Filter by venue</InputLabel>
          <Select
            id="venueFilter"
            labelId="venueFilter"
            name="venueFilter"
            label="Filter by venue"
            defaultValue=""
            open={isVenueSelectOpen}
            onOpen={() => setIsVenueSelectOpen(true)}
            onClose={() => setIsVenueSelectOpen(false)}
            value={selectedVenue}
            onChange={onSelectVenueOption}
            IconComponent={KeyboardArrowDown}
            fullWidth
          >
            {venuesList.options.filter(Boolean).map((venue: string) => (
              <MenuItem key={venue} value={venue}>
                {venue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Filter by event date"
            inputFormat="MM/DD/YYYY"
            /* @ts-expect-error */
            value={selectedDate}
            /* @ts-expect-error */
            onChange={onSelectDateOption}
            // renderInput={(params: any) => <TextField {...params} />}
            views={['year', 'month', 'day']}
            disablePast
            showDaysOutsideCurrentMonth
          />
        </LocalizationProvider>
        <FormControlLabel
          control={
            <Checkbox
              onClick={onClickJustAdded}
              checked={!!justAdded}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 32, marginLeft: '6px' },
                '&.Mui-checked': {
                  '& + .MuiFormControlLabel-label': {
                    color: '#90caf9',
                    fontWeight: 'bold',
                  },
                },
              }}
            />
          }
          sx={{
            '& .MuiTypography-root': {
              fontSize: '13px !important',
            },
          }}
          label="just added"
          labelPlacement="end"
        />
        <FilterButtonsContainer>
          <Button
            size="small"
            sx={{ fontSize: '12px', letterSpacing: 0 }}
            onClick={() => onResetFilters(true)}
          >
            clear
          </Button>
          {viewSize === 'mobile' ? (
            <Button
              style={{ marginLeft: 'auto' }}
              size="small"
              onClick={() => setIsOpen((oldVal: any) => !oldVal)}
            >
              done
            </Button>
          ) : (
            <span>&nbsp;</span>
          )}
        </FilterButtonsContainer>
      </FilterActionsContainer>
      <MobileOnly>
        <Puller onClick={() => setIsOpen((oldVal: any) => !oldVal)} theme={theme} />
      </MobileOnly>
    </ThemeProvider>
  )
}

const MobileContainer = styled.div`
  ${screenSize.mobile(css`
    // padding: 12px 0;
    z-index: 1300;
    position: fixed;
    left: 0;
    right: 0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    // height: ${constants.globalHeaderHeight};
  `)}
  ${screenSize.large(css`
    display: none;
  `)}
`

const MobileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1300;
  background-color: ${colors.globalDarkBlue};
  ${screenSize.large(css`
    display: none;
  `)}
`

const MobileDirectionButton = styled(Button)`
  width: 100%;
  height: ${constants.globalBufferMedium};
  background-color: ${colors.globalDarkBlue};
  color: ${colors.globalLightBlue};
  margin-top: -${constants.globalBufferXS};
  padding-bottom: 4px;
  padding-top: 2px;
  border-bottom: 1px solid ${colors.globalMediumBlue};
  &:hover {
    background-color: ${colors.globalDarkBlue};
  }
`

const DesktopContainer = styled(Section)`
  display: none;
  ${screenSize.large(css`
    display: block;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    padding: 0;
    // background: rgba(255, 255, 255, 0.9);
    background-color: ${colors.globalDarkestBlue};
  `)}
`

const FilterActionsContainer = styled(Stack)`
  margin: 0;
  flex: 1;
  color: #aaa;
  padding: ${constants.globalBufferSmall};
  ${screenSize.large(css`
    padding: ${constants.globalBufferMedium};
    margin: ${constants.globalBufferXS} 0 12px;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    & > * {
      margin-top: 0 !important;
    }
    & .MuiFormControl-root {
      margin: 0;
      padding-right: 20px;
      min-width: 240px;
    }
  `)}
`

const FormControlLabel = styled(MuiFormControlLabel)`
  // border-radius: 4px;
  // border: 1px solid rgba(0, 0, 0, 0.25);
  height: 56px;
  padding: 14px;
  padding-left: 0;
  margin-top: 0;
`

const FilterButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  ${screenSize.mobile(css`
    width: 100%;

    justify-content: space-between;
  `)}
  ${screenSize.large(css`
    flex-direction: row-reverse;
  `)}
`

const Puller = styled(Box)`
  width: 100%;
  height: 6px;
  background-color: rgba(144, 202, 249, 0.25) !important;
  border-radius: 3;
  // position: absolute;
  bottom: 8;
`

const HeaderLink = styled(Link)`
  color: white !important;
  text-decoration: none;
  & :visited,
  :active {
    color: white !important;
  }
`
